










import baseMixin from '@/mixins/baseMixin'
import { isFeatureAccessible } from '@/utils'
import mixins from 'vue-typed-mixins'

export default mixins(baseMixin).extend({
    async beforeMount() {
        if(!this.isPublic) {
            await this.$store.dispatch('getUserInfo')
        }

        if(this.$router.currentRoute.meta?.requiredPermissions && 
        !isFeatureAccessible(this.$router.currentRoute.meta?.requiredPermissions, this.$store.getters.user.permissions)) {
            window.location.href ='/404'
        }
    },
    computed: {
        isPublic(): boolean {
            return this.$router.currentRoute.meta?.public
        },
        shouldShowPage(): boolean {
            return (this.isAuthenticated  && !this.isForbidden) || this.isPublic
        }
    }
})
